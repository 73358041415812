<template>
  <div
    id="field-app"
    class="bg-field-app"
  >
    <!-- <div class="d-flex align-center justify-center logo-field-app">
      <v-img
        :src="appLogo"
        max-height="40px"
        max-width="40px"
        alt="logo"
        contain
        class="me-3 "
      ></v-img>
      <span
        class="font-weight-bold text-xl"
        style="color: white !important; font-family: 'Orbitron';"
      >
        {{ appName }}
      </span>
    </div>
    <div
      class="auth-wrapper auth-v1"
    >
      <div
        class="auth-inner card-width"
      >
        <v-row
          class="d-flex justify-center"
          style="margin-bottom: 100px !important;"
        >
          <v-col
            cols="12"
            md="12"
            sm="10"
            class="align-self-start pa-0"
          >
            <v-card
              color="#1a1a1a"
              class="rounded-lg"
              elevation="15"
              rounded="15"
            >
              <v-card-text class="pa-3">
                <div>
                  <div
                    class="ms-0 width-full"
                  >
                    <v-row class="pa-1 d-flex justify-center">
                      <v-col
                        cols="12"
                        class="d-flex justify-center align-center pb-0"
                      >
                        <div class="d-flex align-center justify-center pt-6">
                          <v-avatar
                            size="140"
                          >
                            <v-img
                              v-if="fieldData.facility_logo"
                              :src="fieldData.facility_logo"
                            ></v-img>
                            <span
                              v-else
                              class="font-weight-medium text-sm"
                            >
                              {{ avatarText(fieldData.facility_name) }}
                            </span>
                          </v-avatar>
                        </div>
                      </v-col>
                      <v-col>
                        <v-col
                          cols="12"
                          class="pa-1 d-flex justify-center align-center"
                        >
                          <span class="text-xl font-weight-bold white--text">{{ fieldData.facility_name }}</span>
                        </v-col>
                        <v-col
                          cols="12"
                          class="mb-1 pa-1 d-flex justify-center align-center"
                        >
                          <span class="text-lg">{{ fieldData.field_name }}</span>
                        </v-col>
                      </v-col>
                    </v-row>
                    <v-row class="justify-center my-3">
                      <v-btn
                        class="ma-0 text-md"
                        color="secondary"
                        rounded
                        width="90%"
                        @click="linkApp"
                      >
                        {{ t('field_app.open_app') }}
                      </v-btn>
                    </v-row>
                    <v-row :class="`justify-center mb-3 ${$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'my-5' : ''}`">
                      <v-btn
                        class="text-lg"
                        style="text-transform: inherit;"
                        text
                        depressed
                        width="90%"
                        height="auto"
                        @click="downloadApp"
                      >
                        <span class="text-lg">
                          {{ t('field_app.dont_have') }} <br v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" /><a><span class="font-weight-bold bg-black-color-red">{{ t('field_app.download') }}</span></a>
                        </span>
                      </v-btn>
                    </v-row>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div> -->
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { ref, onBeforeMount } from '@vue/composition-api'
import { useRouter, downloadAppByOS, openTab } from '@core/utils'
import { mdiPlus, mdiRefresh, mdiLock, mdiDotsVertical, mdiStar } from '@mdi/js'
import { getFieldApp } from '@api'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { loadLanguageAsync } from '@/plugins/i18n'
import { avatarText } from '@core/utils/filter'

export default {
  setup() {
    const QR_FIELD_REDIRECT_URL = process.env.VUE_APP_QR_FIELD_REDIRECT_URL || 'http://localhost:8080/views/video/'

    const fieldData = ref({
      end_playback_time: null,
      facility_logo: null,
      facility_name: null,
      field_name: null,
      highlight_name: null,
      highlight_owner: null,
      highlight_owner_avatar: null,
      share_user: null,
      share_user_avatar: null,
      start_playback_time: null,
      thumbnails: null,
      video_date: null,
      video_id: null,
      video_link: null,
      video_long: null,
      video_thumbnail: null,
      video_time: null,
      video_type: null,
    })
    const fieldRef = ref(null)
    const { t } = useUtils()
    const { route, router } = useRouter()

    onBeforeMount(async () => {
      loadLanguageAsync('en')

      fieldRef.value = route.value.params.id
      const response = await getFieldApp(fieldRef.value)
      if (response.ok) {
        fieldData.value = response.data

        const path = `${QR_FIELD_REDIRECT_URL}${fieldData.value.facility_id}/${fieldData.value.field_id}/all/all`
        openTab(path)
      } else router.push({ name: 'field-not-found' })
    })

    const linkApp = () => {
      openTab('')
      downloadAppByOS(
        `fanaty://addvideos?field_id=${fieldData.value.field_id}&facility_id=${fieldData.value.facility_id}`,
        `fanaty://addvideos?field_id=${fieldData.value.field_id}&facility_id=${fieldData.value.facility_id}`,
        'https://fanaty.com/download',
        true,
      )
    }

    const downloadApp = () => {
      downloadAppByOS(
        'https://play.google.com/store/apps/details?id=com.fanaty',
        'https://apps.apple.com/us/app/fanaty/id1461051554',
        'https://fanaty.com/download',
      )
    }

    return {
      fieldData,
      fieldRef,

      // methods
      linkApp,
      downloadApp,
      avatarText,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // i18n
      t,

      icons: {
        mdiPlus,
        mdiRefresh,
        mdiLock,
        mdiDotsVertical,
        mdiStar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
<style scope>
.img-h {
  width: 100% !important;
  height: 56vh !important;
}

.card-width {
  width: 50rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .img-h {
    width: 100% !important;
    height: 26vh !important;
  }

  .card-width {
    width: 20rem !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .img-h {
    width: 100% !important;
    height: 26vh !important;
  }

  .card-width {
    width: 20rem !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .img-h {
    width: 100% !important;
    height: 50vh !important;
  }

  .card-width {
    width: 50rem !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .img-h {
    width: 100% !important;
    height: 64vh !important;
  }

  .card-width {
    width: 50rem !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .img-h {
    width: 100% !important;
    height: 64vh !important;
  }

  .card-width {
    width: 50rem !important;
  }
}

#field-app {
  font-family: 'Open Sans' !important;
}

.bg-field-app {
  background-color: black;
}

.header-field-app {
  background-color: #ff3232;
  min-height: 6vh;
}

.red {
  color: #ff3232;
}

.bg-black-color-red {
  color: #ff3232;
  background-color: #1a1a1a;
  background: #1a1a1a;
}

.logo-field-app {
  background-color: #1a1a1a;
  min-height: 14vh;
}

.btn-field-app {
  min-height: 12vh;
}

.auth-wrapper {
  min-height: calc(var(--vh, 1vh) * 86);
}

.img-h .v-responsive__content {
  width: 100% !important;
}

.img-h .v-responsive__sizer {
  padding: 0 !important;
}
</style>
